
.page-header{

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray;

    &__filters{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 10px;


        h6{
            margin-right: 20px;
        }

        

        .MuiButtonBase-root{
            font-size: 12px;
            text-decoration: none;
            font-weight: 400;
            text-transform: uppercase;
            padding: 0px;
            background: none;
            border: none;
            cursor: pointer;
            border-radius: 0px !important;
            margin-right: 10px;
            margin-left: 10px;
            padding: 0px 0px !important;
            border-bottom: 1px solid transparent;

            

            &.active{
              
                .MuiButton-label{
                    position: relative;
                    width: auto;
                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 3px;
                        background: rgba(212, 110, 90, 0.5);
                        left: 0px;
                        bottom: 5px;
                    }
    
                }
            }
        }

    }

}