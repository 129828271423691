.dialog {
  &--brand-plate-confirm {
    padding: 25px 40px 25px 25px;
    
    .MuiTypography-h6 {
      font-size: 20px;
      text-transform: none !important;
      color: $medium-gray;
      font-family: "Georgia" !important;
      font-weight: bold;
      letter-spacing: 0px !important;
      padding-top: 10px;
    }

    .MuiDialogContent-root {
      text-align: center;
    }

    .dialog-content-container {
      width: 325px;
    }

    .MuiDialogContent-root {
      padding: 10px 24;
    }

    .MuiDialogContentText-root {
      font-size: 14px;
    }
  }
}