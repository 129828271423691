

form{

    input,select{
        border-radius: 0px !important;
    }

    .MuiFormHelperText-root {
        color: $error;
    }

    .MuiFormControlLabel-root{
        font-family: 'Nunito Sans', sans-serif !important;
        color: #5e5e5e;
    }

}


.MuiInputBase-root{
    font-family: 'Nunito Sans', sans-serif !important;
}


#shipping-form{
    margin-top: 25px;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .doubleLine{
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        align-items: stretch;
        input, .MuiFormControl-root{
            width: auto;
            flex: 1 1 0px;
        }

        .MuiFormControl-root:first-of-type{
            margin-right: 10px;
        }

    }


    
    .tripleLine{
        margin-bottom: 10px;
        display: flex;
        align-items: stretch;
        width: 100%;
        input, select, .MuiFormControl-root{
            width: auto;
            flex: 1 1 0px
        }

        .MuiFormControl-root:first-of-type{
            margin-right: 10px;
        }
        .MuiFormControl-root:nth-of-type(2){
            margin-right: 10px;
        }

    }


    .singleLine{
        margin-bottom: 10px;
        width: 100%; 
        input{
            width: 100%;
        }
    
        .MuiTextField-root{
            width: 100%;
        }
    }

    .button--red-outline{
        right: 0px;
        margin-top: 25px;
    }

}