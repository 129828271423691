.App .profile-billing-template {
  .root {
    width: 100%;
  }

  .paper {
    width: 100%;
    margin-bottom: 16px;
  }

  .header {
    border-bottom: 1px solid $gray;
  }

  .card {
    width: 100%;
    display: flex;
    padding: 20px;
    flex-direction: column !important;
  }

  .cc-display {
    padding: 10px 25px;
    border: 1px solid $gray;
    margin-bottom: 1px;
    letter-spacing: 0.2em;
    color: $gray;
    width: 350px;
  }

  .button {
    margin-top: 20px;
  }

  .text {
    &__payment-header {
      font-size: 24px;
      color: $medium-gray;
    }

    &__card-title {
      font-size: 12px;
      padding-top: 5px;
      letter-spacing: 0.2em;
      padding-bottom: 5px;
  }
  }
}