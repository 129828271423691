

@font-face {
    font-family: 'Georgia';
    src: url('../../fonts/Georgia.ttf')  format('truetype'), /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Georgia';
    font-weight: bold;
    src: url('../../fonts/Georgia-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
  }



h1,h2,h3,h4,h5,h6{
    font-family: "Georgia" , sans-serif;
}

h1,h2,h3{
    letter-spacing: 0.1em !important;
}

p, li{
    color: $gray;
    font-family: 'Nunito', sans-serif;
}

a{
    color: $gray !important;
    text-decoration: underline;
}

h1,h2,h3,h4,h5,h6{
    color: $peach;
}
p{
    color: $gray;
}


.text{

    &--ls-0 {
        letter-spacing: 0px !important;
    }

    &--fs-20 {
        font-size: 20px !important;
    }

    &--fs-10 {
        font-size: 10px !important;
    }

    &--fs-11 {
        font-size: 11px !important;
    }

    &--fs-12 {
        font-size: 12px !important;
    }

    &--fs-13 {
        font-size: 13px !important;
    }

    &--fs-15 {
        font-size: 15px !important;
    }

    &--fs-20 {
        font-size: 20px !important;
    }

    &--fs-21 {
        font-size: 21px !important;
    }
    &--fs-22 {
        font-size: 24px !important;
    }
    &--fs-24 {
        font-size: 24px !important;
    }
    
    &--ls-wide{
       letter-spacing: 4.8px !important;
    }

    &--bold{
        font-weight: bold !important;
    }

    &--nunito{
        font-family: 'Nunito Sans', sans-serif !important;
    }

    &--georgia{
        font-family: "Georgia" !important;
    }

    &--georgia-bold{
        font-family: "Georgia" !important;
        font-weight: bold !important;
    }

    &--upper{
        text-transform: uppercase;
    }

    &--dec-none{
        text-transform: none !important;
    }

    &--center{
        text-align: center !important;
    }

    &--sub{
        font-size: 0.6rem !important;
    }

    &--left{
        text-align: left !important;
    }

    &--error{
        color: $error;
    }
    &--gray{
        color: $gray;
    }
    &--white{
        color: $white !important;
    }
    &--peach{
        color: $peach !important;
    }
    &--blue{
        color: $blue !important;
    }

    &--medium-gray{
        color: $medium-gray !important;
    }

    &--light-gray{
        color: $light-gray;
    }

    &--spacing-15 {
        letter-spacing: 0.15em !important
    }

    &--label{
        font-size: 12px !important; 
        color: $gray;
    }

}

