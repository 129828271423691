.filter-menu-template {
  flex-wrap: wrap;
  justify-content: flex-start;



  .img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &__all {
      background-image: url('../../images/blanka-filter-img-all.png');

    }

    &__eye {
      background-image: url('../../images/blanka-filter-img-eye.png');

    }

    &__lip {
      background-image: url('../../images/blanka-filter-img-lip.png');
    }

    &__face {
      background-image: url('../../images/blanka-filter-img-face.png');
    }

    &__brush {
      background-image: url('../../images/blanka-filter-img-brush.png');
    }

    &__skincare {
      background-image: url('../../images/blanka-filter-img-skincare.png');
    }
  }

 
   

  .filter-card-template {
    border-radius: 3px;
    border: 1px solid $peach;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    height: 78px;
    cursor: pointer;
    min-width: 120px;
    max-width: 15.5%;
    margin-bottom: 15px;
    transition: all 0.50s;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;


    @include medium-down{
      max-width: 46.5%;
      flex-grow: 1;
    }


    &::after{
      content: '';
      background-color: rgba(0, 0, 0, 0);
      position: absolute;
      top:0px;
      left: 0px;
      width: 100%;
      height:100%;
      z-index: 2;
      transition: all 0.50s;
    }

    &:hover{
      &::after{
        content: '';
        background-color: rgba(0, 0, 0, 0.72);
      }
      .text__header{
        //text-decoration: underline;
      }
    }
    
    &.is-active{
      &::after{
        content: '';
        background-color: rgba(0, 0, 0, 0.72);


     
      }
      cursor: default;

      .text__header{
        //text-decoration: underline;
      }
    }

    .text {
     
      &__header {
        text-decoration: none;
        letter-spacing: 6px;
        color: $white !important;
        position: relative;
        z-index: 3;
        font-size: 14px;
      }
    }
  }
}