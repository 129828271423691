.confirm-modal{
   
    .button{
        margin-top: 25px;
    }


    .modal__body{
        background-color: $peach;
        background-image: url('../../images/icon-error.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 90%;   
        color: $white;

        .text__title{
            color: $white;
        }

        a{
            color: $white !important;
        }
    

    }

    
    .modal-close-button{
        color: $white;
    }

  



}