@import "react-alice-carousel/lib/scss/alice-carousel.scss";

@import './colors.scss';

//global
@import './global/text';
@import './global/mixins';
@import './global/normalize';
@import './global/spacing.scss';
@import './global/utils';

@import './appbar.scss';

//templates
@import './templates/profile-main';
@import './templates/profile-logo-upload';
@import './templates/samples-checkout';
@import './templates/brand-plate';
@import './templates/my-products';
@import './templates/find-products';
@import './templates/login.scss';
@import './templates/order-index';
@import './templates/inventory-checkout';

//Modules
@import './modules/buttons.scss';
@import './modules/table.scss';
@import './modules/forms';
@import './modules/paper';

//components
@import './components/supplier-cards.scss';
@import './components/shop-card.scss';
@import './components/order-card.scss';
@import './components/modal.scss';
@import './components/toast';
@import './components/tags';
@import './components/icons';
@import './components/sidebar';
@import './components/accordion';
@import './components/payment-prices';
@import './components/chip';
@import './components/progress';
@import './components/checkout-breadcrumbs';
@import './components/page-header';
@import './components/free-plan-limit-modal';
@import './components/add-payment-method-modal';
@import './components/stepper';
@import './components/onboarding.scss';
@import './components/product-modal.scss';
@import './components/product-form.scss';
@import './components/profile-billing.scss';
@import './components/profile-store.scss';
@import './components/profile-password.scss';
@import './components/profile-billing-update.scss';
@import './components/profile-password-update.scss';
@import './components/password-reset.scss';
@import './components/shopify-onboarding.scss';
@import './components/standalone-onboarding-step-four.scss';
@import './components/confirm-modal';
@import './components/dialog.scss';
@import './components/plan-modal-card.scss';
@import './components/plan-wrapper.scss';
@import './components/filter-menu.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body{
  background: $white;
}

.App-container{
  //padding-top: 100px;
}

.App-content {
  margin-left: 275px;
  margin-right: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}


.MuiTableContainer-root{
  box-shadow: none !important;
  border: 0.5px solid $light-gray;
  border-radius: 0px !important;
}



#root .App .loading-screen{
  position: fixed;
  width: 100%;    
  z-index: 9999999;
  background: #FFF;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .MuiLinearProgress-root{
    position: relative !important;
  }
}


.MuiCard-root {
  border-radius: 0px;
}

.hidden {
  display: none !important;
}


.color-swatch {
  width: 50px;
  height: 50px;
}
