
.template-my-products{
    .root {
        width: 100%;
        border: none;
    }

    .shop-card__img{
        padding-top: 0px;
        padding-left: 0px;
    }

    .product__image{
        position: relative;
    }

    .image {
        width: 100%;
    }

    .MuiCardContent-root {
        
        // cuases  error in tag centereing
        //padding-left: 0px;
    }


    .button {
        &__back {
            margin-right: 8px;
        }
    }

    .text {
        &__product-name {
            padding-bottom: 10px;
        }

        &__publish-tag {
            font-size: 10px;
            color: #CCCCCC;
        }
    }

    .instructions {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .actions {
        justify-content: flex-end;
    }

    .product__image {
        width: 100%;
    }

    .MuiFormControlLabel-label {
        font-size: 12px;
        color: #CCCCCC;
        font-family: 'Nunito', sans-serif;
    }

    .MuiSwitch-root {
        MuiButtonBase-root Mui-checked {
            color: $green;
        }

        .MuiSwitch-thumb {
            color: white;
        }
    }
  
}