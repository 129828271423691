.plan-limit-modal{

    text-align: center;

    .root {
        display: flex;
        flex-direction: column;
    }

    li {
        font-family: 'Nunito Sans', sans-serif !important;
    }
    
    .modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal__plans{
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;

        .payment_price_item{
            height: 330px;
        }

    

        .button {
            &__modal-center-plan {
                // bottom: -2px !important;
            }

            &__modal {
                bottom: 9px;
            }
        }
    }

    .try-free-button {
        width: 225px;

        .MuiButton-label {
            letter-spacing: 0px;
        }
    }

    .modal-close-button{
        position: absolute;
        text-transform: uppercase;
        color: #5e5e5e;
        font-size: 8px;
        font-weight: 100;
        letter-spacing: 2.4px;
        cursor: pointer;

        top: 10px;
        right: 10px;
    }

    .payment_price_item{
     text-align: center;   
     margin-top: 25px;
     width: auto;
    }

    .text {
        &__body {
            width: 80%;
            margin: 0px auto;
            margin-top: 15px;
            font-size: 14px;
        }
        
        &__header {
            font-family: "Georgia" , sans-serif;
            font-size: 21px;
            color: $medium-gray;
            padding-top: 40px;
        }

        &__try-free {
            font-size: 14px;
            color: $blue;
            margin-top: 0px;
            margin-bottom: 5px;
        }

        &__plan-name {
            font-size: 16px;
            letter-spacing: 0em !important;
        }
    }

    .MuiCardActions-root{
        justify-content: center;
        
        //aligns buttons on bottom
        position: absolute;
        width: 100%;
        bottom: 30px;
    }

    

    @include medium-down{

        .modal__plans{
          width: 100%;
        }
    
        .plan-limit-modal{
          flex-direction: column; 
        }
    
      }
}