.template-inventory-checkout{
    font-size: 12px;
    text-transform: uppercase;
    .item__table{
        background: #FCFCFC;
        border: 0.5px solid #979797;

        .MuiTableCell-head{
            font-weight: 900;
            font-size: 10px;
            line-height: 14px;
            letter-spacing: 2px;
            color: $gray;
        }
    }

    .MuiTypography-body1 {
        font-size: 12px;
        letter-spacing: 2px;
    }

    .MuiTableCell-root {
        padding: 6px;

    }

  

    .tag{
        width: fit-content;
        margin-top: 5px;
    }

    .checkout__breadcrumbs{


        .MuiSvgIcon-root{
            font-size: 12px;
            margin-bottom: 0.5px;
        }

        p{
            font-weight: 400;
            font-size: 8px;
            text-transform: uppercase;
            letter-spacing: 3.2px;
        }

        p.back{
            cursor: pointer;
        }

        p.back:hover{
            cursor: pointer;
            font-weight: bold;
        }
    }
    .MuiTableCell-root {
        vertical-align: top !important;
    }

    

    .name-tag-cell{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 12px;
    }

    .btn--continue-shipping{
        display: flex;
        justify-content: flex-end;
    }

    .quantity__cell{
        width: 100px;
    }

    .MuiTableCell-root, td {
        text-transform: uppercase !important;
    }

    .totals-container {
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        text-align: right;
        padding-right: 16px;
        padding-bottom: 15px;
        max-width: 300px;
        margin-left: auto;

        .MuiTableCell-root, td {
            text-transform: uppercase !important;
        }


        &__values-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
        }

        .MuiTypography-body1 {
            min-width: 50px;
            margin-bottom: 10px;
        }

        &--border-top{
            border-top: 1px solid #CCCCCC;
            padding-top: 10px;
        }


        .button--red-outline{
            margin-top: 25px;
        }
    }
    .MuiTableCell-head{
        font-family: 'Nunito Sans', sans-serif !important;
    }
    .tracking-number-container {
        margin-top: 10px;
        display: flex;
        justify-content:space-between;
        padding-left: 15px;
        align-items: flex-start;

        .flex--flex{
            align-items: center;
        }
        .icon--truck{
            margin-right: 10px;
        }

    }

    .fulfill-inventory-container{
        text-align: right;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }
    .MuiFormGroup-root,.MuiFormControlLabel-root{
        justify-content: flex-end;
    }

}

.template-inventory-checkout__shipping{
    font-size: 12px;

    .MuiPaper-root{
        background: #FCFCFC;
        border: 0.5px solid #979797;
    }
    
}

.totals-container {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    text-align: right;
    padding-right: 16px;
    padding-bottom: 15px;
    max-width: 300px;
    margin-left: auto;
    text-transform: uppercase !important;
    
}