.App .template-profile-password {
  .root{
    width: 100%;
  }

  .header-text-container {
    text-align: left;
  }

  .card {
    padding: 20px;
  }

  .text {
    &__title {
      font-size: 24px;
    }

    &__header {
      font-size: 12px;
    }

    &__secure-message{
      color: $medium-gray !important;
      font-size: 12px;
    }

    &__current-password {
      font-size: 12px;
      padding-top: 20px;
      letter-spacing: 0.2em;
      padding-bottom: 8px;
    }
  }

  .form-text-field {
    border-radius: 0px !important;
    width: 300px;
  }

  .password-display {
    text-align: center;
    padding: 10px;
    border: 1px solid $gray;
    margin-bottom: 10px;
    letter-spacing: 0.2em;
    color: $gray;
    max-width: 255px;
  }

  .update-password{
    font-size: 10px !important;
    letter-spacing: 0.1em;

    &__container {
      padding-bottom: 10px;
    }
  }

  .button {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .password-list-container {
    margin: 0px;    padding-bottom: 15px;

    .password-list {
      font-size: 10px;
    }
  }


}