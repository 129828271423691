body .MuiButtonBase-root{
    border-radius: 4px !important;
    box-shadow: none !important;
    font-family: 'Nunito Sans', sans-serif !important;

    transition: background-color 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    .fas{
        margin-left: 5px;
        font-size: 13px;
        line-height: 0px;
        margin-top: 1px;
        margin-bottom: 2px;
    }    
}

.MuiButtonBase-root{
    .MuiButton-label{
        align-items: center;
        display: flex;
        font-weight: 700;
        letter-spacing: 6px;
        justify-content: center;
        line-height: 27px;
    }
}

.MuiButtonBase-root.Mui-disabled {
    border: none  !important;
    background: $light-gray !important
    //border: 1px solid $gray !important;
}

.MuiButton-outlinedPrimary:hover {
    background: $peach !important;
    color: $white !important;
}

.MuiButton-containedPrimary:hover {
    background: $white !important;
    color: $peach !important;
    border: 1px solid $peach !important;
}

.MuiButton-contained {
    background: $peach;
    color: $white;
    border: 1px solid $peach !important;

    &.Mui-disabled{
        border: transparent !important;
    }
}

#root .button{
   
    &--primary{
        background: $peach;
        color: $white;
        border: 1px solid $peach;

        &:hover {
            background: $white !important;
            color: $peach !important;
            border: 1px solid $peach !important;
        }
    }

    &--outline{
        border: 1px solid $gray;
        color: $gray !important;
    }

    &--red-outline{
        border: 1px solid $peach !important;
        background: transparent !important;
        color: $peach !important;

        &:hover {
            background: $peach !important;
            color: $white !important;
        }
    }

    &--blue{
        background-color: $blue;
        border: 1px solid $blue !important;
        color: $white !important;

        &:hover {
            border: 1px solid $blue !important;
            background-color: white;
            color: $blue !important;
        }
    }

    &--unpublish{
        background: transparent;
        border: 1px solid $peach !important;
        color: $peach !important;
    }

    .MuiButton-contained.Mui-disabled{
        border:transparent !important;
    }
    
}

/*
Loading button component styles
*/
.loading-button {
    position: relative;
    &.loading{
        .loading-button__text{
            visibility: hidden;
        }
    }
    &__icon-wrapper{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#root .MuiButtonBase-root.Mui-disabled {
    border: none  !important;
    background: $light-gray;
    //border: 1px solid $gray !important;
}