

.template-find-products{



    .page-header__visualize{
        margin-bottom: 5px;
    }


    .paper{
        width: 100%;
        margin-bottom: 50px;
        margin-top: 25px;
    }


    .MuiTable-root{
        overflow-x: hidden;
    }


    .MuiGrid-item {
        padding-top: 0px !important;
    }

    .button {
        &__filters {
            .MuiButton-label {
                font-weight: 400;
                letter-spacing: 2.5px;
            }
        }
    }


    .find-products__img{
        position: relative;
        min-height: 225px;
        margin-top: 15px;
        cursor: pointer;
        
        .product-image{
            //min-height: 225px;
            position: absolute;
            top: 50%;
            left:50%;
            transform: translate(-50%,-50%);
        }

        &__placeholder{
            margin: 0 auto;
            
            position: absolute;
            top: 50%;
            left:50%;
            transform: translate(-50%,-50%);
        }
    }

   

}