.plan-modal-card {


  &.payment-price-item--center{
      height: 380px;
      margin-top: -20px;
  }  



  .plan-modal-card .MuiCardContent-root {
    padding: 0px !important;
    padding-right: 10px !important;
    line-height: 1.6;
  }

  li span {
    position: relative;
    right: 10px !important;
  }

  .plan-details-container {
    display: flex;
    justify-content: center;
  }

  .MuiButtonBase-root .MuiButton-label {
    line-height: 24px;
    letter-spacing: 6px;
  }

  .text {
    &__title {
      text-align: center;
      letter-spacing: 0px;
    }
  }

  .button {
    &__plans {
      background: $peach;
      color: #fff;
      position: absolute;
      bottom: 43px;
      left: 50%;
      font-size: 13px;
      width: 225px;
      transform: translate(-50%, 0%);
    }

    &__modal {
      bottom: 30px;
      
    }

    &__modal-center-plan {
      bottom: 17px;
    }

  }


  .try-free-container {
    li span {
        position: relative;
        left: -10px;
    }
    @include medium-down{
      flex-direction: column;
    }
  }

  /* Ribbon Styling */
  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    // background-color: white;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 6px 0;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: $peach;
    font-size: 7px;
    text-align: center;
    letter-spacing: 1.5px;
  }

  /* top right*/
  .ribbon-top-right {
    top: -10px;
    right: -10px;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right span {
    left: -4px;
    top: 33px;
    transform: rotate(45deg);
  }
}