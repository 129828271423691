.product-modal {

  position: relative;
  
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-size: 12px;
    font-weight: 400;
  }

  .accordion {
    margin: 25px 0px;

    .MuiAccordion-root.Mui-expanded:before {
      opacity: 1 !important;
    }
  }
  .sample-note{
    font-size: 12px;
    font-style: italic;
    margin-top: 5px;
  }



  .modalRight{
    width: 40%;
  }
  .modalLeft{
    width:60%;
    
    img{
      max-height: 400px;
      width: 100%;
      margin: 0 auto;
      height: auto;
      object-fit: contain;
    }

    .product_img-container{
      position: relative;
      img{
        width: auto;
        height: 500px;
      
      }
    }
  }

  .MuiAccordion-root .MuiAccordionDetails-root {
    p, ul, li, span {
        font-size: 12px;
        font-weight: 100 !important;
    }
  }

  .text {
    &__product-name {
      font-size: 15px;
    }

    &--product-details {
      font-size: 20px;
    }

    &__profit {
      color: $peach;
    }
    
    &__dollar {
      font-size: 18px;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0px 0px;
  }

  .carousel .thumb.selected, .carousel .thumb:hover {
    //border: none;
    border: 1px solid $light-gray;
  }
  
  .carousel .thumb:focus{
    border: 1px solid $light-gray;
  }

  .carousel.carousel-slider {
    max-height: 500px;
    height: 400px;

    
  }
  .carousel .thumbs{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider-wrapper{
    max-height: 400px;

  }


  @include medium-down{


    .modalLeft,.modalRight{
      width: 100%;
    }

    .product-modal__wrapper{
      flex-direction: column; 
      max-height: unset;
      width: 95%;
    }

  }



}