.plan-wrapper-container {
  display: flex;
  flex-direction: row;
  height: 50%;


  @include medium-down{
    flex-direction: column;
    

    .plan-modal-card{
      .MuiButtonBase-root{
        
        bottom: 20px;
        width: 225px;
      }
    }
  }


  .button {
    &__modal-center-plan {
      bottom: 67px;
    }
  }
}

.plan-wrapper-container-standalone {
  .payment_price_item {
    height: 250px;
  }

  .payment_price_item.payment_price_item--center {
    height: 290px;
  }
}