.product-modal, .onboarding-modal, .add-logo-modal, .bl-modal, .plan-limit-modal {
    position: relative;
    overflow: scroll !important;
    z-index: 999999;
    border-radius: 5px;

    &:focus{
        outline: none;
    }

    .MuiButton-label {
        letter-spacing: 0px;
    }

    ul {
        line-height: 1.6;
    }

    .try-free-container {
        li span {
            position: relative;
            left: -10px;
        }
    }

    .modal-close-button{
        position: absolute;
        text-transform: uppercase;
        color: #5e5e5e;
        font-size: 8px;
        font-weight: 100;
        letter-spacing: 2.4px;
        cursor: pointer;
        top: 12px;
        right: 9px;
    }

    .modal__close{
        position: absolute;
        text-transform: uppercase;
        color: #5e5e5e;
        font-size: 8px;
        font-weight: 100;
        letter-spacing: 2.4px;
        cursor: pointer;

        top: 10px;
        right: 10px;
    }

    .modal-container{
        display: flex;
        flex-direction: row;
        margin-top: 50px;
    }

    .modalRight {
        position: relative;
        width: 50%;
        padding-bottom: 150px;;
        
    }

    .MuiAccordion-root .MuiAccordionDetails-root {
        p, ul, li, span {
            font-size: 12px;
            font-weight: 100;
        }
    }

    .MuiAccordionDetails-root {
        display: flex;
        padding: 8px 16px 16px;
    }

    ul li{
        font-size: 12px;
    }

    .modalLeft {
        position: relative;
        width: 50%;
        min-height: 400px;
        padding-right: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            height: auto;
       
        }
    }

    .button--outline {
        margin-bottom: 5px;
    }

    button {
        width: 100%;
    }

    .modal__actions {
        position: absolute;
        bottom: 0px;
        right: 0px;
        
        left: 60px;
    }

    .dropzone {
        padding: 20px;
        border: 1px dashed $light-gray;
        margin-bottom: 50px;
    }

    .product-images{
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        img{
            width: 125px;
            padding:10px;
        }
    }
}

.onboarding-modal, .add-logo-modal {

    .MuiCardActions-root {
        position: absolute;
        bottom: 0px;
        width: 100%;
        

        .MuiButtonBase-root {
            position: relative;
        }
    }

    .dropzone {
        height: 200px;
        color: #fff;
        background: rgba($peach, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        p{
            color: $white;
        }
    }

    .modalRight {
        position: relative;
        width: 60%;

        ul{
            padding-left: 20px;
        }
    }

    .modalLeft {
        position: relative;
        width: 40%;
         padding: 10px;
        img {
            width: 100%;
        }
    }
}


.makeStyles-paper-44{
    margin-top: 100px !important;
}

.onboarding-modal{
       overflow: scroll;
       margin-top: 100px;
       margin-bottom: 50px;
}


.add-logo-modal{
    .modal{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .welcome{
        display: flex;
        flex-direction: row;
      }

    .makeStyles-paper-77{
       width: 60%;
       
    }

    .title{
        text-align: center;
        color: $medium-gray;
        font-size: 35px;
        padding-top: 15px;
    }

    .plans{
        width: 33%;
        padding-bottom: 50px;
        position: relative;
    }

    .modal-right{
        padding-bottom: 20px;
        width: 50%;
    }

    .modal-left{
        min-height: 200px;
        width: 50%;
    }

    @include medium-down{

        .add-logo-container{
          width: 100%;
        }
    
        .add-logo-modal{
          flex-direction: column; 
        }
    
      }

}

.change-plan-modal {
    .welcome {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        font-size: 30px;
        color: $medium-gray;
    }




    .button {
        position: absolute;
        bottom: 10px;
        left: 15px;
        width: 225px;

        .MuiButton-label {
            letter-spacing: 0px;
        }
    }

    .plans {
        display: flex;
        flex-direction: row;
    }

    .modal-content {
        padding: 50px 50px;
        padding-bottom: 30px;
    }

    .change-plan-modal-wrapper{
        position: relative;

        .payment_price_item{
            height: 330px;

            &--center{
                transform: scale(1.1);
                z-index: 999;
            }
        }

        @include medium-down {

            .MuiButton-root{
              padding: 0px 16px;
            }
            
            .button__modal {
                left: 10px;
            }
        }
    }
}