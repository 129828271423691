
.template-onboarding{
  display: flex;
  background-image: url('../../images/onboarding_background.jpg');
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  @include medium-down{
    height: auto;
    min-height: 100vh;


  }

  .welcome {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    &__name {
      color: $medium-gray !important;
      font-size: 24px !important;
    }
  }

  .MuiCard-root {
    border-radius: 5px !important;
  }

  .pointer {
    cursor: pointer;
  }

  .store-connected-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    .button--blue{
      margin-top: 25px;
    }
  }

  .step-three-container {
    .stripe {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .card-element-container {
        width: 100%;
      }
    }
  }

  .modal__step-1 {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }

  .welcome-text-box {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: left;
    align-content: center;
    margin: 0 auto;
    margin-bottom: 5px;
  }

  .welcome-container {
    margin: 0 auto;
    max-width: 450px;
  }

  .signup-container {
    display: flex;
    text-align: left;
    padding-left: 30%;
    flex-direction: column;

    &__title{
      color: $blue;
      font-weight: 1000;
      font-size: 24px;
    }

    &__sign-in {
      font-size: 9px;
    }
  }

  .terms-conditions-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;

    .terms-text {
      font-size: 10px;
      color: $gray;
      padding-top: 5px;
    }

    .check {
      border-radius: 1px;
    }
  }

  /* form alignment here */
  .stripe{
    max-width: 500px;
    margin: 0 auto;
    .MuiTextField-root{
      width: 48%;

      &:nth-child(2){
        margin-left: 2%;
      }
    }

    .StripeElement{
      border-color: rgba(0, 0, 0, 0.23);
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 0px;
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .form-text-field {
      margin: 10px;
      width: 410px;
    }

    .form-text-field__name {
      width: 195px;
    }
  }

  .paper {
    background: #fff;
    width: 80%;
    max-width: 1000px;
    height: fit-content;
    padding: 40px;
    border-radius: 0px;
  }

  .button {
      margin: 0 auto;
      // margin-top: 100px !important;

      &:hover {
        background-color: transparent;
      }

      &__upload-next {
        background-color: $blue !important;
        color: #fff;
        width: 235px;
      }
      
      &__upload-img {
        width: 200px;
      }

      &__try-free {
        position: absolute;
        bottom: 20px;
        left: 95px;

        .MuiButton-label {
          letter-spacing: 0px;
          font-size: 12px;
        }
      }
  }

  .logo {
    width: 150px;
    height: auto;
    padding: 10px;
    margin-left: 2px;
  }

  .stepper {
    text-align: center;

    .MuiStepper-horizontal {
      justify-content: center;
    }

    &__hidden {
      display: none
    }
  }

  /*
  All styles related to the stepper are here
  */
  .MuiStepper-root{
    .MuiSvgIcon-root {
      display: none;
    }
    .MuiSvgIcon-root {
      display: none;
    }

    .MuiStepLabel-label {
      color: $medium-gray;
    }

    .MuiStepLabel-active{
      color: $gray;
    }

    .MuiTypography-body2 {
      font-family: 'Nunito', sans-serif;
      font-size: 8px;
      letter-spacing: 3px !important;
      text-transform: uppercase;
      font-weight: bold;

    }
  
    .MuiStepConnector-root {
      //display: none;
      flex: 0;

      .MuiStepConnector-line{
        border: none;

        &::after{
          content: '\f054';
          font-family: "Font Awesome 5 Free"; 
          font-weight: 900;
          font-size: 8px;
          line-height: 23px;
        }
      }
    }
  }

  .text__redirect-link a{
    color: $white !important;
    font-weight: bold;
    cursor: pointer;
  }

  .button__initial-step {
    margin-top: 25px;
    max-width: 300px;
    margin: 25px auto;
    width: 100%;
  }

  

  //TODO: reduce number of classes and remove important
  .text {
    &__try-free-plan {
      text-align: center;
      font-size: 24px !important;
      margin-bottom: 25px;
      color: $blue !important;
    }

    &__signup-thank-you {
      font-family: 'Nunito Sans', sans-serif !important;
    }

    &__pick-plan {
      padding-bottom: 55px;
      color: $medium-gray !important;
      font-size: 22px !important;
    }

    &__try-growth {
      color: $gray;
      font-size: 24px;
    }

    &__try-free-stripe {
      text-align: center;
      font-size: 24px !important;
      color: $blue !important;
    }

    &__change-name {
      margin-right: 10px;
    }

    &__change-postal {
      margin-bottom: 25px;
    }
    
    &__terms {
      font-size: 10px;
      color: $gray;
    }
    
    &__upload-logo {
      text-align: center;
      color: $medium-gray !important;
      font-size: 24px !important;
    }

    &__actions {
      font-family: 'Nunito Sans', sans-serif !important;
    }

    &__drop-file {
      margin-inline: 8px;
    }

    &__error {
      color: #AB0000 !important;
      font-family: 'Nunito Sans', sans-serif !important;
    }

    &__error-email-used {
      color: #AB0000 !important;
      font-family: 'Nunito Sans', sans-serif !important;
      text-decoration: none;
    }
  }

  .plans {
    display: flex;
    flex-direction: row;
    height: 50%;

    .flex-column-center{
      align-items: flex-start;
      max-width:200px;
      margin: 0 auto;
    }


  }

  .dropzone {
    height: 200px;
    color: #fff;
    border-color: rgba($peach, 0.5) !important;
    border-style: solid;
    background: rgba($peach, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      color: $white;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .img {
    &__container {
      position: relative;
      max-width: 300px;
      margin-left: auto;
    }

    &__preview {
      display: block;
      height: auto;
      width: 100%;;
      max-height: 250px;
      object-fit: cover;
      margin-left: auto;
    }
  }

  .modal-right,.modal-left {
    width: 50%;
  }

  .modal-right{
    padding-left: 25px;
    text-align: left;
  }

  .modal-right {
    position: relative;
    width: 50%;
  }

  ul {
    // padding-left: 60px;
    text-align: left;
    font-size: 12px;
    // max-width: 200px;
    // margin: 0 auto;
  }

  .payment_price_item .MuiCardContent-root {
    padding: 20px;
    line-height: 1.6;
  }

  @include medium-down{

    .text__pick-plan {
      padding-bottom: 5px;
    }

    .text__try-free-plan {
      margin-bottom: 5px;
    }
    
    .paper {
      display: flex;
      flex-direction: column;
      width: 85%;
      height: auto;
      padding: 20px;
    }

    .modal-content {
      flex-direction: column;
    }

    .stripe {
      .MuiTextField-root{
        &:nth-child(2){
          margin-left: 0;
        }
      }
    }

    .form {
      width: 100%;
    }

    .text__change-name {
      margin-right: 0px;
    }

    .terms-conditions-container {
      display: flex;
      justify-content: center;
    }

    .button__initial-step {
      margin-top: 25px;

    }

    .button__plans-media {
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto;
      
    }

    .button__try-free-media {
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto;
      width: 195px;
    }

    .button__initial-step-billing {
      width: 221px;
    }

    .signup-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      padding-left: 0%;
    }

    .plans {
      display: flex;
      flex-direction: column;
      margin-top: 50px;
    }

    .payment_price_item {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 0px !important;
    }

    .free-plan-container {
      margin-bottom: 35px;
    }

    .pick-plan-container {
      display: flex;
      flex-direction: column;
      height: auto;
    }

    .row {
      flex-direction: column;
      justify-content: center;
    }
    
    .form{
      .MuiTextField-root{
        width: 300px;
      }
    }
    
    .payment_price_item .MuiCardContent-root {
      padding: 0px !important;
      padding-right: 10px !important;
      line-height: 1.6;


      @include medium-down{
        padding: 20px !important;
        height: 300px;
      }

    }
  }
}