



.flex{

    &--flex {
        display: flex;
    }
    
    &-column{
        &-center{
            display: flex;
            flex-direction: column;   
            justify-content: center;
            align-items: center;
        }

        &-left{
            display: flex;
            flex-direction: column;   
            justify-content: center; 
            align-items: flex-start;
        }
    }

    &-row{
        &-center{
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.display {
    &--none {
        display: none !important;
    }

    &--inline {
        display: inline !important;
    }
}

.mb{

    &-5{
        margin-bottom: 5px !important;
    }

    &-10{
        margin-bottom: 25px !important;
    }

    &-25{
        margin-bottom: 25px !important;
    }


}

.ml {
    &-auto {
        margin-left: auto;
    }
}

.mw {
    &-50 {
        min-width: 50px !important;
    }
    
}

.pt {
    &-10 {
        padding-top: 10px !important;
    }

    &-15 {
        padding-top: 15px !important;
    }

    &-20 {
        padding-top: 20px !important;
    }

    &-25 {
        padding-top: 25px !important;
    }

    &-30 {
        padding-top: 30px !important;
    }
}


.pb {
    &-10 {
        padding-bottom: 10px !important;
    }

    &-15 {
        padding-bottom: 15px !important;
    }

    &-20 {
        padding-bottom: 20px !important; 
    }

    &-25  {
        padding-bottom: 25px !important;
    }

    &-30 {
        padding-bottom: 30px !important;
    }

    &-40 {
        padding-bottom: 40px !important;
    }
}

.pl {
    &-5 {
        padding-left: 5px !important;
    }

    &-10 {
        padding-left: 10px !important;
    }
    &-15 {
        padding-left: 15px !important;
    }
}

.pr {
    &-5 {
        padding-right: 5px !important;
    }
    
    &-10 {
        padding-right: 10px !important;
    }
    &-15 {
        padding-right: 15px !important;
    }
}

.mt {

    &-15 {
        margin-top: 15px !important;
    }

    &-20 {
        margin-top: 20px !important;
    }

    &-25 {
        margin-top: 25px !important;
    }

    &-32 {
        margin-top: 32px !important;
    }
}

.mr {
    &-10 {
        margin-right: 10px !important;
    }

    &-15 {
        margin-right: 15px !important;
    }
}

.ta {
    &-left {
        text-align: left;    
    }

    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }
}
