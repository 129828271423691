.payment_price_item, .payment_price_item--center {
    border: 1px solid #dddddd;
    position: relative;
    padding-bottom: 100px;
    width: 33%;
}

.payment_price_item:hover, .payment_price_item--center:hover {
    border: 1px solid #dddddd;
}


.payment_price_item--selected {
    color: #ffffff;
    background-image: -ms-linear-gradient(-140deg,#246071 0,#062832 100%);
    background-image: -webkit-linear-gradient(-140deg,#246071 0,#062832 100%);
    height: 360px !important;
    margin-top: -15px !important;

    ul li {
        color: $white;
    }
}

.payment_price_item h6 {
    font-family: 'Georgia', serif;
    font-weight: bold;
    text-transform: uppercase;
}

.payment_price_item ul {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    padding-left: 10px;
}


.payment_price_item .payment_price_btn {
    border-radius: 2px;
}

.payment_priceing_area::before {
    background: none;
}

.payment_price_item, .payment_price_item h2, .payment_price_item h6, .payment_price_item p {
    color: #246071;
}

.payment_price_item{
        -webkit-transition: background-image 0.2s ease-in-out;
        transition: background-image 0.2s ease-in-out;
        
        background-image: transparent;

        h2,h3,h4,h5, ul, li,p{
            color: $blue;
        }

        .MuiCardContent-root{
            padding: 40px;
            padding-right: 30px;
        }

        &:hover{
          
            background: -ms-linear-gradient(-140deg,#246071 0,#062832 100%);
            background: -webkit-linear-gradient(-140deg,#246071 0,#062832 100%);
            color: #ffffff;
            
            h2,h3,h4,h5, ul, li,p{
                color: $white;
            }
        }

}

.payment_price_item--center {
 
    color: #ffffff;
    -webkit-transition: opacity .4s linear;
    -o-transition: opacity .4s linear;
    transition: opacity .4s linear;
    
    background-image: -ms-linear-gradient(-140deg,#246071 0,#062832 100%);
    background-image: -webkit-linear-gradient(-140deg,#246071 0,#062832 100%);

    h2,h3,h4,h5, ul, li,p{
        color: $white;
    }

    ul li{
        color: $white;

    }
   
}
