
$baseline-padding: 20px;

.mb{

    &-25{
        margin-bottom: 25px;
    }

    &-10{
        margin-bottom: 10px;
    }

}

.mt{

    &-25{
        margin-top: 25px;
    }

}

.pb {

    &-5{
        padding-bottom: 5px;
    }

    &-10{
        padding-bottom: 10px;
    }

    &-15{
        padding-bottom: 15px;
    }
}

.pt {
    &-10 {
        padding-top: 10px;
    }

    &-20 {
        padding-top: 20px;
    }

    &-40 {
        padding-top: 40px;
    }

    &-50 {
        padding-top: 50px;
    }

    &-15 {
        padding-top: 15px;
    }
}

.bl-1{
    padding: $baseline-padding;
}

.bl-2{
    padding: $baseline-padding*2;
}
