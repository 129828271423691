.orders-view {
  .root {
    width: 100%;
  }

  .header {
    border-bottom: 1px solid $gray;
    margin-bottom: 50px;
  }

  .table {
    border-collapse: collapse;
    margin: 16;
    width: 50%;
    min-width: 350px;
  }

  .MuiGrid-container {
    flex-direction: column;
    align-items: baseline;
  }

  .table-data {
    padding: 8;
  }

  .orders__list{
    width: 100%;


    .pagination-container {
      border-top: 1px solid #5E5E5E;
      display: flex;
      justify-content: center;


      .MuiPaginationItem-page.Mui-selected {
        background-color: transparent !important;
        font-weight: 800;
        border-radius: 0px !important;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 5px;
          background: rgba(230,136,128,0.5);
          left: 0px;
          bottom: 4px;
        }
      }

      .MuiPaginationItem-page {
        &:hover {
          background-color: transparent !important;
          font-weight: 800;
          border-radius: 0px !important;

          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 5px;
            background: rgba(230,136,128,0.5);
            left: 0px;
            bottom: 4px;
        }
        }
      }

      .Mui-disabled {
        background-color: transparent !important;
      }
    }
  }

  .paper {
    width: 100%;
    margin-bottom: 16px;
  }

  .order__list__no-orders-text{
    letter-spacing: 0.00938em !important;
  }

  .orders__skeleton_container{
    width: 100%;
  }
  .orders__skeleton{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;

    &--split{
      display: flex;
      justify-content: space-between;
    }

    &--right{
      margin-left: auto;
    }
   
    .MuiSkeleton-root{
      margin-bottom: 20px;
    }
  }

  .fulfill-form{
    text-transform: uppercase;
  }

  .MuiChip-root{
    border-radius: 2.5px;
    .MuiChip-label{
      text-transform: uppercase;
      line-height: 22px;
    }
  }

  .status{
    

    &-Payment-Required{
      background-color: $blue;
    }
    &-unfulfilled{
      background-color: $orange;
    }
    &-ordered{
      background-color: $orange;
    }
    &-fulfilled{
      background-color: $green;
    }
    &-shipped{
      background-color: $green;
    }
    &-Cancelled{
      background-color:$error;
    }



  }

  
  .text {
    &--title {
      letter-spacing: 0.2em;
      color: $gray;
      font-size: 12px;
    }
  }
}